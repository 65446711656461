<!--  -->
<template>
  <div class='news'>
    <img :src="info.image" v-if="info.image" alt=""/>
    <div v-html="info.text"></div>
  </div>
</template>

<script>
import Api from "../api/my"

export default {
  components: {},
  data() {
    return {
      info: {}
    };
  },
  computed: {},
  watch: {
    "$route.query.id" () {
      document.getElementById("app").scrollTop = 0
      this.getConsultMsg()
    }
  },
  created() {
    document.getElementById("app").scrollTop = 0
    this.getConsultMsg()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    getConsultMsg () {
      Api.getConsultMsg({id: this.$route.query.id}).then(res => {
        if (res.data.code == 1) {
          this.info = res.data.data
          document.getElementById("app").scrollTop = 0
        } else {
          this.$message.error("咨询详情获取失败")
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
.news {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 30px;
  text-indent: 30px;
  img {
    margin: 20px auto 0;
    max-width: 90%;
  }
  p {
    width: 92%;
    padding-right: 10px;
    max-height: 1200px;
    overflow-y: auto;
    margin-bottom: 40px;
    margin-top: 20px;
    word-break: break-all;
    white-space: pre-wrap;
    text-indent: 30px;
    &::-webkit-scrollbar{
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track{
      background: rgb(239, 239, 239);
      border-radius:2px;
    }
    &::-webkit-scrollbar-thumb{
      background: #bfbfbf;
      border-radius:10px;
    }
    &::-webkit-scrollbar-thumb:hover{
      background: #333;
    }
    &::-webkit-scrollbar-corner{
      background: #179a16;
    }
  }
}
</style>